import { FC, useContext, useState } from "react";
import { CustomRequestSection } from "../Components/Sections/CustomRequestSection";
import { useTranslation } from "react-i18next";
import { IFile } from "../types/IFile";
import { FilesUpload } from "../Components/Sections/Common/SubSections/FilesUpload";
import { InfoBox } from "../Elements/Alert/InfoBox";
import { IInputComponentProps, Input } from "../Elements/Input";
import { ISimpleObject, TextInput } from "@inceptionbg/ui-components";
import { UserContext } from "../Context/UserContext";
import { setTempHolder } from "../repos/FarmRepo";
import { SelectAsyncPaginate } from "../Components/Inputs/SelectAsyncPaginate";
import {
  getEducationOptions,
  getWorkActivityOptions,
} from "../utils/LoadOptions";
import { inputPattern } from "../utils/InputPatternValidation";

export const SetTempHolderPage: FC = () => {
  const [orgNumber, setOrgNumber] = useState("");
  const [workActivity, setWorkActivity] = useState<ISimpleObject>();
  const [accountNumber, setAccountNumber] = useState("");
  const [education, setEducation] = useState<ISimpleObject>();
  const [birthPlace, setBirthPlace] = useState("");
  const [storedFiles, setStoredFiles] = useState<IFile[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const { t } = useTranslation();
  const { user } = useContext(UserContext);

  const data: IInputComponentProps[] = [
    {
      label: t("OrgNumberLong"),
      input: <TextInput value={orgNumber} setValue={setOrgNumber} />,
    },
    { label: t("TempHolderFirstName"), value: user?.firstName },
    { label: t("TempHolderLastName"), value: user?.lastName },
    { label: t("TempHoldertPIN"), value: user?.personalIdentityNumber },
    {
      label: t("WorkActivity"),
      value: workActivity?.name || "/",
      input: (
        <SelectAsyncPaginate
          required
          value={
            workActivity
              ? {
                  label: workActivity.name,
                  value: workActivity.uuid,
                }
              : null
          }
          loadOptions={getWorkActivityOptions}
          onChange={(e) =>
            setWorkActivity(e ? { name: e.label, uuid: e.value } : undefined)
          }
        />
      ),
    },
    {
      label: t("AccountNumber"),
      labelHelper: t("AccountNumberHelper"),
      value: accountNumber,
      input: (
        <TextInput
          value={accountNumber}
          setValue={setAccountNumber}
          required
          inputProps={{ pattern: inputPattern.bankAccount }}
        />
      ),
    },
    {
      label: t("Education"),
      value: education?.name || "/",
      input: (
        <SelectAsyncPaginate
          required
          value={
            education
              ? {
                  label: education?.name,
                  value: education?.uuid,
                }
              : null
          }
          loadOptions={getEducationOptions}
          onChange={(e) =>
            setEducation(
              e
                ? {
                    name: e.label,
                    uuid: e.value,
                  }
                : undefined
            )
          }
        />
      ),
    },
    {
      label: t("BirthPlace"),
      value: birthPlace,
      input: <TextInput value={birthPlace} setValue={setBirthPlace} required />,
    },
  ];

  const onSubmit = () => {
    setIsLoading(true);
    setTempHolder(orgNumber, {
      bankAccount: { accountNumber },
      education: { uuid: education?.uuid! },
      workActivity,
      evidences: storedFiles.map((storedFile) => ({
        storedFile,
        category: "HOLDER",
      })),
      birthPlace,
    })
      .then(() => {
        setStoredFiles([]);
      })
      .catch(() => {})
      .finally(() => setIsLoading(false));
  };

  return (
    <CustomRequestSection
      sectionTitle={t("TempHolderTitle")}
      infoBoxTitle={t("RequestTempHolderTitle")}
      infoBoxDesc={t("RequestTempHolderDesc")}
      onSubmit={onSubmit}
      disableSubmit={
        !orgNumber ||
        !workActivity ||
        !storedFiles.length ||
        !education ||
        !birthPlace
      }
    >
      <div className="form-container mb-4">
        {data.map(
          (e) =>
            !e.hidden && <Input key={e.label} component={e} edit={!e.edit} />
        )}
      </div>
      <FilesUpload
        storedFiles={storedFiles}
        setStoredFiles={setStoredFiles}
        isLoading={isLoading}
        setIsLoading={setIsLoading}
        infoBoxItems={[
          { title: t("HolderDeathCertificate") },
          {
            title: t("MembersStatementForTempHolder"),
            file: {
              label: t("DownloadMembersStatement"),
              url: "https://erpg.eagrar.gov.rs/upload/Prilog-4.pdf",
            },
          },
        ]}
      />
      <InfoBox
        title={t("Statement")}
        desc={t("TempHolderStatement")}
        className="mx-4"
      />
    </CustomRequestSection>
  );
};
