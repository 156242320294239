import { FC, ReactElement, useContext } from "react";
import { Route, Routes } from "react-router-dom";
import { PageWrapper } from "./Components/Wrapper/PageWrapper";
import { DashboardPage } from "./Pages/DashboardPage";
import MobileDeviceUploadPage from "./Pages/MobileDeviceUploadPage";
import { RPGPage } from "./Pages/RPGPage";
import { ISidebarType } from "./types/IBase";
import { StatementSection } from "./Components/Sections/Statements/StatementSection";
import { StatusChangeSection } from "./Components/Sections/StatusChange/StatusChangeSection";
import { DeleteSection } from "./Components/Sections/Delete/DeleteSection";
import { CaseUpdateSection } from "./Components/Sections/CaseUpdate/CaseUpdateSection";
import { GeneralRequestSection } from "./Components/Sections/GeneralRequest/GeneralRequestSection";
import { ComplaintSection } from "./Components/Sections/Complaint/ComplaintSection";
import { SetTempHolderPage } from "./Pages/SetTempHolderPage";
import { ChangePPHolderPage } from "./Pages/ChangePPHolderPage";
import { ChangeLegalRepPage } from "./Pages/ChangeLegalRepPage";
import { FarmContext } from "./AppContent";
import { UserContext } from "./Context/UserContext";
import { pageNoAccess } from "./utils/PageAccess";
import { InvoicesPage } from "./Pages/InvoicesPage";
import { RefactionPage } from "./Pages/RefactionPage";
import { PesticideProcurementsPage } from "./Pages/PesticideProcurementsPage";

interface IRoute {
  path?: string;
  element?: ReactElement;
  sidebar?: ISidebarType;
  title?: string;
  helpButton?: {
    isOpen?: boolean;
  };
  noAccess?: boolean;
}

export const Router: FC = () => {
  const { userInfo } = useContext(UserContext);
  const { farm } = useContext(FarmContext);

  const noAccess = pageNoAccess(farm, userInfo);

  const routes: IRoute[] = [
    { path: "/", element: <DashboardPage /> },
    {
      path: "/edit",
      title: "PageTitleEDIT",
      helpButton: { isOpen: false },
      sidebar: "data",
      element: <RPGPage mode="EDIT" />,
      noAccess: noAccess.edit,
    },
    {
      path: "/renew",
      title: "PageTitleRENEW",
      helpButton: {},
      sidebar: "data",
      element: <RPGPage mode="RENEW" />,
      noAccess: noAccess.renew,
    },
    {
      path: "/new",
      title: "PageTitleNEW",
      helpButton: {},
      sidebar: "data",
      element: <RPGPage mode="NEW" />,
      noAccess: noAccess.new,
    },
    {
      path: "/statements",
      title: "PageTitleSTATEMENTS",
      helpButton: {},
      sidebar: "other",
      element: <StatementSection />,
      noAccess: noAccess.statements,
    },
    {
      path: "/status-change",
      title: "PageTitleSTATUS_CHANGE",
      helpButton: {},
      sidebar: "other",
      element: <StatusChangeSection />,
      noAccess: noAccess.statusChange,
    },
    {
      path: "/delete",
      title: "PageTitleDELETE",
      helpButton: {},
      sidebar: "other",
      element: <DeleteSection />,
      noAccess: noAccess.delete,
    },
    {
      path: "/change-holder-pp",
      title: "PageTitleCHANGE_HOLDER_PP",
      helpButton: {},
      sidebar: "other",
      element: <ChangePPHolderPage />,
      noAccess: noAccess.changeHolderPP,
    },
    {
      path: "/set-temp-holder",
      title: "PageTitleSET_TEMP_HOLDER",
      helpButton: {},
      sidebar: "other",
      element: <SetTempHolderPage />,
      noAccess: noAccess.setTempHolder,
    },
    {
      path: "/change-holder-le",
      title: "PageTitleCHANGE_HOLDER_LE",
      helpButton: {},
      sidebar: "other",
      element: <ChangeLegalRepPage />,
    },
    {
      path: "/case-update",
      title: "PageTitleCASE_UPDATE",
      helpButton: {},
      sidebar: "other",
      element: <CaseUpdateSection />,
    },
    {
      path: "/general-request",
      title: "PageTitleGENERAL_REQUEST",
      helpButton: {},
      sidebar: "other",
      element: <GeneralRequestSection />,
    },
    {
      path: "/complaint",
      title: "PageTitleCOMPLAINT",
      helpButton: {},
      sidebar: "other",
      element: <ComplaintSection />,
    },
    {
      path: "/receipts",
      title: "FuelInvoices",
      helpButton: {},
      sidebar: "other",
      element: <InvoicesPage />,
    },
    {
      path: "/refaction",
      title: "Refaction",
      helpButton: {},
      sidebar: "other",
      element: <RefactionPage />,
    },
    {
      path: "/pesticide-procurements",
      title: "PesticideProcurements",
      helpButton: {},
      sidebar: "other",
      element: <PesticideProcurementsPage />,
    },
  ];
  const pubRoutes: IRoute[] = [
    { path: "/mobile-upload", element: <MobileDeviceUploadPage /> },
  ];

  return (
    <Routes>
      {routes.map((route) => (
        <Route
          key={route.path}
          path={route.path}
          index={!route.path}
          element={
            <PageWrapper
              title={route.title}
              sidebar={route.sidebar}
              helpButton={route.helpButton}
              noAccess={route.noAccess}
            >
              {route.element}
            </PageWrapper>
          }
        />
      ))}
      {pubRoutes.map((route) => (
        <Route
          key={route.path}
          path={route.path}
          index={!route.path}
          element={route.element}
        />
      ))}
    </Routes>
  );
};
