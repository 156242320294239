import {
  ISimpleObject,
  ISimpleObjectWithCode,
} from "@inceptionbg/ui-components";
import { IApproval } from "./IBase";
import { IFarmNew } from "./IFarm";
import { IEvidence } from "./IFile";
import { IMemberNew } from "./IMember";

export const landUsageBasis = [
  "CADASTRE_OWNERSHIP",
  "OWNERSHIP_OUT_CADASTRE",
  "CONSOLIDATION",
  "LEASE",
  "LEASE_LEGAL_ENTITY",
  "LEASE_CONSOLIDATION",
] as const;
export type ILandUsageBasis = (typeof landUsageBasis)[number];

export const productionType = ["CONVENTIONAL", "ORGANIC"] as const;
export type IProductionType = (typeof productionType)[number];

export interface ILandNew {
  uuid?: string;
  usageBasis?: ILandUsageBasis | "UPZ";
  cadastreMunicipality?: ISimpleObject;
  cadastreNumber?: string;
  cadastreCulture?: ISimpleObjectWithCode;
  surface?: number | string;
  surfaceObj?: ISurfaceObj;
  usedSurface?: number | string;
  usedSurfaceObj?: ISurfaceObj;
  leaseEndDate?: string;
  evidences?: IEvidence[];
  member?: IMemberNew;
  nickname?: string;
  lessorIdentification?: string;
  leaseType?: ISimpleObjectWithCode;
  approval?: Partial<IApproval>;
}

export interface ILand {
  uuid: string;
  usageBasis: ILandUsageBasis | "UPZ";
  upzBase?: ISimpleObjectWithCode;
  cadastreMunicipality: ISimpleObject;
  cadastreNumber: string;
  cadastreCulture: ISimpleObjectWithCode;
  surface: number;
  usedSurface: number;
  nickname?: string;
  leaseEndDate?: string;
  evidences?: IEvidence[];
  member?: IMemberNew;
  approved?: boolean;
  lessorIdentification?: string;
  leaseType?: ISimpleObjectWithCode;
  approval?: IApproval;
  literRecapitulation?: ILiterRecapitulation;
}

export interface ILiterRecapitulation {
  leftLiter?: number;
  maxLiter?: number;
  usedLiter?: number;
}

export interface ISurfaceObj {
  ha?: string;
  a?: string;
  m?: string;
}

export type LandFetchOption =
  | "CADASTRE_CULTURE"
  | "CADASTRE_MUNICIPALITY"
  | "VERFICATION_STATUS"
  | "MEMBER"
  | "APPROVAL"
  | "UPZ_BASE"
  | "LEASE_TYPE"
  | "LITER_RECAPITULATION";

export interface ICultureNew {
  uuid?: string;
  plantCulture?: IPlantCulture;
  productionType?: IProductionType;
  surface?: number | string;
  surfaceObj?: ISurfaceObj;
  hailProtectionSurface?: number | string;
  hailProtectionSurfaceObj?: ISurfaceObj;
  numberOfSeedlings?: number | string;
  irrigation?: boolean;
  numberOfTrees?: number | string;
}

export interface ICulture extends ICultureNew {
  uuid: string;
  plantCulture: IPlantCulture;
  productionType?: IProductionType;
  surface?: number;
  hailProtectionSurface?: number;
  numberOfSeedlings?: number;
  irrigation?: boolean;
  maxLiter?: number;
  literRecapitulation?: ILiterRecapitulation;
}
export interface ILandPlantCulture extends ICulture {
  land?: ILand;
}

export interface IPlantCulture {
  uuid: string;
  name: string;
  code: string;
  requireNumberOfSeedlings: boolean;
}
export interface ILandsSum {
  totalByCultures: ICulture[];
  totalSurface: number;
  totalSurfaceUnderCultures: number;
}

export interface ILandPart {
  partNumber?: string;
  partSurface?: number;
  cadastreCulture?: ISimpleObjectWithCode;
}

export interface ILandReport {
  farm: IFarmNew;
  type: ILandReportType;
  cadastreMunicipality: ISimpleObject;
  cadastreNumber: string;
  usedSurface: string;
  note?: string;
}
export interface ILandReportNew extends Partial<ILandReport> {
  usedSurfaceObj?: ISurfaceObj;
}

export const landReportType = [
  "WITHOUT_LEASE_AGREEMENT",
  "CONSOLIDATION",
  "UPZ",
] as const;
export type ILandReportType = (typeof landReportType)[number];
