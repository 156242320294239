import { TextInput } from "@inceptionbg/ui-components";
import {
  Dispatch,
  FC,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { FarmContext } from "../../../../AppContent";
import { IInputComponentProps, Input } from "../../../../Elements/Input";
import { updateHolder } from "../../../../repos/FarmRepo";
import {
  legalEntitySize,
  IHolderNew,
  LEForms,
} from "../../../../types/IHolder";
import { IFarmNew } from "../../../../types/IFarm";
import { inputPattern } from "../../../../utils/InputPatternValidation";
import { Select } from "../../../Inputs/Select";
import { SubSection } from "../../../SubSection";
import { Form } from "../../../Wrapper/FormWrapper";

interface Props {
  setFarm?: Dispatch<SetStateAction<IFarmNew>>;
}

export const LegalEntityInfo: FC<Props> = ({ setFarm }) => {
  const [legalEntity, setLegalEntity] = useState<IHolderNew>({});
  const [formData, setFormData] = useState<IHolderNew>({});
  const [edit, setEdit] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const { t } = useTranslation();

  const farm = useContext(FarmContext).farm;
  const reloadFarm = useContext(FarmContext).reloadFarm;

  useEffect(() => {
    if (farm && !setFarm) {
      setLegalEntity(farm.holder || {});
      setFormData(farm.holder || {});
    }
  }, [farm, setFarm]);

  useEffect(() => {
    !!setFarm && setFarm((e) => ({ ...e, holder: formData }));
  }, [formData, setFarm]);

  const legalEntityInfo: IInputComponentProps[] = [
    {
      label: t("OrganizationalForm"),
      value: legalEntity.legalEntityForm
        ? t("LEForm" + legalEntity.legalEntityForm)
        : "/",
      input: (
        <Select
          required
          value={
            formData.legalEntityForm
              ? {
                  label: t("LEForm" + formData.legalEntityForm),
                  value: formData.legalEntityForm,
                }
              : null
          }
          options={LEForms.map((e) => ({ label: t("LEForm" + e), value: e }))}
          onChange={(e) =>
            setFormData({ ...formData, legalEntityForm: e.value })
          }
        />
      ),
    },
    {
      label: t("LegalEntitySize"),
      value: legalEntity.size ? t(legalEntity.size) : "/",
      input: (
        <Select
          required
          value={
            formData.size
              ? {
                  label: t(formData.size),
                  value: formData.size,
                }
              : null
          }
          options={legalEntitySize.map((e) => ({ label: t(e), value: e }))}
          onChange={(e) => setFormData({ ...formData, size: e.value })}
        />
      ),
    },
    {
      label: t("RegistrationNumber"),
      value: legalEntity.registrationNumber,
      input: (
        <TextInput
          required
          value={formData.registrationNumber}
          setValue={(e) => setFormData({ ...formData, registrationNumber: e })}
          inputProps={{ pattern: inputPattern.registrationNumber }}
        />
      ),
    },
    {
      label: t("TaxId"),
      value: legalEntity.taxId,
      input: (
        <TextInput
          required
          value={formData.taxId}
          setValue={(e) => setFormData({ ...formData, taxId: e })}
          inputProps={{ pattern: inputPattern.taxId }}
        />
      ),
    },
    {
      label: t("Jbkjs"),
      value: legalEntity.jbkjs,
      input: (
        <TextInput
          value={formData.jbkjs}
          setValue={(e) => setFormData({ ...formData, jbkjs: e })}
          inputProps={{ pattern: inputPattern.jbkjs }}
        />
      ),
    },
    {
      label: t("DedicatedRegisterNumber"),
      value: legalEntity.dedicatedRegisterNumber,
      input: (
        <TextInput
          value={formData.dedicatedRegisterNumber}
          setValue={(e) =>
            setFormData({ ...formData, dedicatedRegisterNumber: e })
          }
        />
      ),
    },
    {
      label: t("Name"),
      value: legalEntity.name,
      input: (
        <TextInput
          required
          value={formData.name}
          setValue={(e) => setFormData({ ...formData, name: e })}
        />
      ),
    },
    {
      label: t("NumberOfEmployees"),
      value: `${legalEntity.numberOfEmployees || "/"}`,
      input: (
        <TextInput
          required
          value={`${formData.numberOfEmployees || "/"}`}
          inputProps={{ type: "number" }}
          setValue={(e) => setFormData({ ...formData, numberOfEmployees: e })}
        />
      ),
    },
  ];

  const onSubmit = () => {
    if (farm?.uuid) {
      setIsLoading(true);
      updateHolder(farm.uuid, formData)
        .then(() => {
          reloadFarm(() => {
            setIsLoading(false);
            setEdit(false);
          });
        })
        .catch(() => setIsLoading(false));
    }
  };

  return (
    <SubSection
      title={t("LegalEntityInfoTitleOrdered")}
      expandable={!setFarm}
      alerts={
        setFarm
          ? []
          : [
              {
                text: `${t("WaitingComplementText")}
                        ${formData.approval?.statusComment}`,
                type: "error",
                number: formData.approval?.approvalNumber,
                hidden:
                  formData.approval?.approvalStatus !== "WAITING_COMPLEMENT",
              },
              {
                text: t("LegalEntityNotVerifiedInfo"),
                type: "info",
                number: formData.approval?.approvalNumber,
                hidden:
                  !!formData.approved ||
                  formData.approval?.approvalStatus === "WAITING_COMPLEMENT",
              },
            ]
      }
    >
      {setFarm ? (
        <div className="form-container">
          {legalEntityInfo.map(
            (e) => !e.hidden && <Input key={e.label} component={e} edit />
          )}
        </div>
      ) : (
        <Form
          data={legalEntityInfo}
          edit={edit}
          setEdit={setEdit}
          submitButton={{
            editLabel: t(edit ? "Confirm" : "Edit"),
            onSubmit,
          }}
          onResetForm={() => setFormData(legalEntity)}
          isLoading={isLoading}
        />
      )}
    </SubSection>
  );
};
