import { Button } from "@inceptionbg/ui-components";
import { FC, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { AppContext } from "../Context/AppContext";
import { PageWrapper } from "../Components/Wrapper/PageWrapper";
import { Loader } from "../Elements/Loader";
import { getToken, login } from "../repos/AuthRepo";
import { IUser } from "../types/IUser";
import { localStorageGet } from "../utils/LocalStorageHelper";
import { setUserFromToken } from "../utils/TokenUtils";
import { parseUrlSearch } from "../utils/UrlUtils";

interface Props {
  setUser: (user: IUser | null) => void;
}

export const LoginPage: FC<Props> = ({ setUser }) => {
  const [isLoading, setIsLoading] = useState(false);

  const { t } = useTranslation();
  const { search } = useLocation();
  const navigate = useNavigate();
  const {
    screen: { contentHeight },
  } = useContext(AppContext);

  useEffect(() => {
    const { code } = parseUrlSearch(search);
    const token = localStorageGet("token");
    if (code && !token) {
      setIsLoading(true);
      getToken(code)
        .then((token) => {
          setUserFromToken(token, setUser);
          navigate("/");
        })
        .catch(() => {})
        .finally(() => setIsLoading(false));
    }
  }, [search, setUser, navigate]);

  return (
    <PageWrapper isPublic>
      <Loader isLoading={isLoading}>
        <div
          style={{ minHeight: contentHeight }}
          className="flex column gap-4 justify-center align-center"
        >
          <h1 className="text-center">{t("LoginTitle")}</h1>
          <Button
            label={t("Login")}
            size="l"
            primary
            onClick={() => login()}
            className="w-auto"
          />
        </div>
      </Loader>
    </PageWrapper>
  );
};
